<nav class="navbar navbar-expand-lg navbar-light header-navbar " [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
        <li class="nav-item mr-2 d-none d-sm-block">
          <ng-select class="min-with-300 cu" [(ngModel)]="ActiveClinic" (change)="setActiveClinic($event)">
            <ng-option value="1">Clinic 1</ng-option>
            <ng-option value="2">Clinic 2</ng-option>
            <ng-option value="3">Clinic 3</ng-option>
          </ng-select>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo">
                <a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img">
                    <img class="logo-img" alt="Apex logo" [src]="logoUrl">
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item min-with-300 mr-3" *ngIf="this.activeRoute === 'dashboard'">
            <div class="dp-hidden position-absolute">
              <div class="input-group">
                <input
                  name="datepicker"
                  class="form-control border-0"
                  ngbDatepicker
                  #datepicker="ngbDatepicker"
                  [autoClose]="'outside'"
                  (dateSelect)="onDateSelection($event)"
                  [displayMonths]="2"
                  [dayTemplate]="t"
                  outsideDays="hidden"
                  [startDate]="fromDate!"
                  tabindex="-1"
                />
                <ng-template #t let-date let-focused="focused">
                  <span
                    class="custom-day"
                    [class.focused]="focused"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null"
                  >
                    {{ date.day }}
                  </span>
                </ng-template>
              </div>
            </div>
            <div class="input-group mb-0">
                <fieldset class="form-group position-relative has-icon-right w-100 bg-white  mb-0">
                    <input
                        #dpFromDate
                        class="form-control"
                        placeholder="From - To"
                        name="dpFromDate"
                        [value]="formatRange()"
                        (input)="parseRange(dpFromDate.value)"
                        (click)="datepicker.toggle()"
                    />
                    <div class="form-control-position">
                        <i class="icon-calendar"></i>
                    </div>
                </fieldset>  
            </div>
          </li>
          <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;"
              data-toggle="dropdown" ngbDropdownToggle><i class="ft-bell font-medium-3"></i><span
                class="notification badge badge-pill badge-danger">4</span></a>
            <ul ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">7 New
                      Notification</span>
                  </div>
                  <span class="text-bold-400 cursor-pointer">Mark all as read</span>
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <a class="d-flex justify-content-between" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/avatar-s-20.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Kate Young</span><small class="grey lighten-1 font-italic float-right">5 mins
                          ago</small>
                      </h6>
                      <small class="noti-text">Commented on your photo</small>
                      <h6 class="noti-text font-small-3 m-0">
                        Great Shot John! Really enjoying the composition on this
                        piece.
                      </h6>
                    </div>
                  </div>
                </a>
                <a class="d-flex justify-content-between" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/avatar-s-11.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Andrew Watts</span><small class="grey lighten-1 font-italic float-right">49 mins
                          ago</small>
                      </h6>
                      <small class="noti-text">Liked your album: UI/UX Inspo</small>
                    </div>
                  </div>
                </a>
                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">
                  <div class="media d-flex align-items-center py-0 pr-0">
                    <div class="media-left">
                      <div class="mr-3">
                        <img src="assets/img/icons/sketch-mac-icon.png" alt="avatar" height="45" width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">Update</h6>
                      <small class="noti-text">MyBook v2.0.7</small>
                    </div>
                    <div class="media-right">
                      <div class="border-left">
                        <div class="px-4 py-2 border-bottom">
                          <h6 class="m-0 text-bold-600">Update</h6>
                        </div>
                        <div class="px-4 py-2 text-center">
                          <h6 class="m-0">Close</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-primary bg-lighten-3 mr-3 p-1">
                        <span class="avatar-content font-medium-2">LD</span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Registration done</span><small class="grey lighten-1 font-italic float-right">6 hrs
                          ago</small>
                      </h6>
                    </div>
                  </div>
                </a>
                <div class="cursor-pointer">
                  <div class="media d-flex align-items-center justify-content-between">
                    <div class="media-left">
                      <div class="media-body">
                        <h6 class="m-0">New Offers</h6>
                      </div>
                    </div>
                    <div class="media-right">
                      <div class="custom-switch custom-control">
                        <input type="checkbox" class="custom-control-input" id="custom-switch-1111" checked>
                        <label class="custom-control-label mr-1" for="custom-switch-1111">
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between cursor-pointer read-notification">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-danger bg-lighten-4 mr-3 p-1">
                        <span class="avatar-content font-medium-2"><i class="ft-heart text-danger"></i></span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Application approved</span><small class="grey lighten-1 font-italic float-right">18 hrs
                          ago</small>
                      </h6>
                    </div>
                  </div>
                </div>
                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/avatar-s-6.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Anna Lee</span><small class="grey lighten-1 font-italic float-right">27 hrs ago</small>
                      </h6>
                      <small class="noti-text">Commented on your photo</small>
                      <h6 class="noti-text font-small-3 text-bold-500 m-0">
                        Woah!Loving these colors! Keep it up
                      </h6>
                    </div>
                  </div>
                </a>
                <div class="d-flex justify-content-between cursor-pointer read-notification">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-info bg-lighten-4 mr-3 p-1">
                        <div class="avatar-content font-medium-2">
                          <i class="ft-align-left text-info"></i>
                        </div>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Report generated</span><small class="grey lighten-1 font-italic float-right">35 hrs
                          ago</small>
                      </h6>
                    </div>
                  </div>
                </div>
                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/avatar-s-7.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Oliver Wright</span><small class="grey lighten-1 font-italic float-right">2 days
                          ago</small>
                      </h6>
                      <small class="noti-text">Liked your album: UI/UX Inspo</small>
                    </div>
                  </div>
                </a>
              </li>
              <li class="dropdown-menu-footer">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1">
                  Read All Notifications
                </div>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle dropdown-app p-0 mt-1 mr-1" id="drp-app" href="javascript:;"
              data-toggle="dropdown" ngbDropdownToggle><i class="icon-grid font-medium-3"></i></a>
            <ul ngbDropdownMenu
              class="app-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden app-layer-1 p-2">
              <div class="bg-white p-2">
                <div class="row m-0 align-items-center justify-items-evenly">
                  <div class="col-4 text-center app-item">
                    <a type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="twitter">
                        <path
                          d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z">
                        </path>
                      </svg>
                      <h6 class="mt-auto">Item 1</h6>
                    </a>
                  </div>
                  <div class="col-4 text-center app-item">
                    <a type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="instagram">
                        <path
                          d="M12,9.52A2.48,2.48,0,1,0,14.48,12,2.48,2.48,0,0,0,12,9.52Zm9.93-2.45a6.53,6.53,0,0,0-.42-2.26,4,4,0,0,0-2.32-2.32,6.53,6.53,0,0,0-2.26-.42C15.64,2,15.26,2,12,2s-3.64,0-4.93.07a6.53,6.53,0,0,0-2.26.42A4,4,0,0,0,2.49,4.81a6.53,6.53,0,0,0-.42,2.26C2,8.36,2,8.74,2,12s0,3.64.07,4.93a6.86,6.86,0,0,0,.42,2.27,3.94,3.94,0,0,0,.91,1.4,3.89,3.89,0,0,0,1.41.91,6.53,6.53,0,0,0,2.26.42C8.36,22,8.74,22,12,22s3.64,0,4.93-.07a6.53,6.53,0,0,0,2.26-.42,3.89,3.89,0,0,0,1.41-.91,3.94,3.94,0,0,0,.91-1.4,6.6,6.6,0,0,0,.42-2.27C22,15.64,22,15.26,22,12S22,8.36,21.93,7.07Zm-2.54,8A5.73,5.73,0,0,1,19,16.87,3.86,3.86,0,0,1,16.87,19a5.73,5.73,0,0,1-1.81.35c-.79,0-1,0-3.06,0s-2.27,0-3.06,0A5.73,5.73,0,0,1,7.13,19a3.51,3.51,0,0,1-1.31-.86A3.51,3.51,0,0,1,5,16.87a5.49,5.49,0,0,1-.34-1.81c0-.79,0-1,0-3.06s0-2.27,0-3.06A5.49,5.49,0,0,1,5,7.13a3.51,3.51,0,0,1,.86-1.31A3.59,3.59,0,0,1,7.13,5a5.73,5.73,0,0,1,1.81-.35h0c.79,0,1,0,3.06,0s2.27,0,3.06,0A5.73,5.73,0,0,1,16.87,5a3.51,3.51,0,0,1,1.31.86A3.51,3.51,0,0,1,19,7.13a5.73,5.73,0,0,1,.35,1.81c0,.79,0,1,0,3.06S19.42,14.27,19.39,15.06Zm-1.6-7.44a2.38,2.38,0,0,0-1.41-1.41A4,4,0,0,0,15,6c-.78,0-1,0-3,0s-2.22,0-3,0a4,4,0,0,0-1.38.26A2.38,2.38,0,0,0,6.21,7.62,4.27,4.27,0,0,0,6,9c0,.78,0,1,0,3s0,2.22,0,3a4.27,4.27,0,0,0,.26,1.38,2.38,2.38,0,0,0,1.41,1.41A4.27,4.27,0,0,0,9,18.05H9c.78,0,1,0,3,0s2.22,0,3,0a4,4,0,0,0,1.38-.26,2.38,2.38,0,0,0,1.41-1.41A4,4,0,0,0,18.05,15c0-.78,0-1,0-3s0-2.22,0-3A3.78,3.78,0,0,0,17.79,7.62ZM12,15.82A3.81,3.81,0,0,1,8.19,12h0A3.82,3.82,0,1,1,12,15.82Zm4-6.89a.9.9,0,0,1,0-1.79h0a.9.9,0,0,1,0,1.79Z">
                        </path>
                      </svg>
                      <h6 class="mt-auto">Item 2</h6>
                    </a>
                  </div>
                  <div class="col-4 text-center app-item">
                    <a type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="linkedin">
                        <path
                          d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z">
                        </path>
                      </svg>
                      <h6 class="mt-auto">Item 3</h6>
                    </a>
                  </div>
                  <div class="col-4 text-center app-item">
                    <a type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"
                        id="whatsapp">
                        <path
                          d="M16.6 14c-.2-.1-1.5-.7-1.7-.8-.2-.1-.4-.1-.6.1-.2.2-.6.8-.8 1-.1.2-.3.2-.5.1-.7-.3-1.4-.7-2-1.2-.5-.5-1-1.1-1.4-1.7-.1-.2 0-.4.1-.5.1-.1.2-.3.4-.4.1-.1.2-.3.2-.4.1-.1.1-.3 0-.4-.1-.1-.6-1.3-.8-1.8-.1-.7-.3-.7-.5-.7h-.5c-.2 0-.5.2-.6.3-.6.6-.9 1.3-.9 2.1.1.9.4 1.8 1 2.6 1.1 1.6 2.5 2.9 4.2 3.7.5.2.9.4 1.4.5.5.2 1 .2 1.6.1.7-.1 1.3-.6 1.7-1.2.2-.4.2-.8.1-1.2l-.4-.2m2.5-9.1C15.2 1 8.9 1 5 4.9c-3.2 3.2-3.8 8.1-1.6 12L2 22l5.3-1.4c1.5.8 3.1 1.2 4.7 1.2 5.5 0 9.9-4.4 9.9-9.9.1-2.6-1-5.1-2.8-7m-2.7 14c-1.3.8-2.8 1.3-4.4 1.3-1.5 0-2.9-.4-4.2-1.1l-.3-.2-3.1.8.8-3-.2-.3c-2.4-4-1.2-9 2.7-11.5S16.6 3.7 19 7.5c2.4 3.9 1.3 9-2.6 11.4">
                        </path>
                      </svg>
                      <h6 class="mt-auto">Item 4</h6>
                    </a>
                  </div>
                  <div class="col-4 text-center app-item">
                    <a type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="date">
                        <path
                          d="M19,4H17V3a1,1,0,0,0-2,0V4H9V3A1,1,0,0,0,7,3V4H5A3,3,0,0,0,2,7V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm1,15a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12H20Zm0-9H4V7A1,1,0,0,1,5,6H7V7A1,1,0,0,0,9,7V6h6V7a1,1,0,0,0,2,0V6h2a1,1,0,0,1,1,1Z">
                        </path>
                      </svg>
                      <h6 class="mt-auto">Item 5</h6>
                    </a>
                  </div>
                  <div class="col-4 text-center app-item">
                    <a type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" id="notes" x="0" y="0" version="1.1" viewBox="0 0 29 29"
                        xml:space="preserve">
                        <path
                          d="M21.495 13.163v10.342a1 1 0 01-1 1h-15a1 1 0 01-1-1v-12h7.343l6.001-6H5.495c-1.654 0-3 1.346-3 3v15c0 1.654 1.346 3 3 3h15c1.654 0 3-1.346 3-3V11.162l-2 2.001zM24.505 7.323l1.414-1.414a2 2 0 10-2.828-2.828l-1.414 1.414 2.828 2.828z">
                        </path>
                        <path d="M20.262 5.909l-7.07 7.071-.708 3.536 3.536-.708 7.071-7.07z"></path>
                      </svg>
                      <h6 class="mt-auto">Item 6</h6>
                    </a>
                  </div>

                </div>
              </div>
            </ul>
          </li>
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">John Doe</span><span
                  class="text-right text-muted font-small-3">Available</span>
              </div>
              <img class="avatar" src="assets/img/portrait/small/avatar-s-1.png" alt="avatar" height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <a class="dropdown-item" routerLink="/pages/account-settings">
                <div class="d-flex align-items-center">
                  <i class="ft-user mr-2"></i><span>Profile</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/pages/login">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>
          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i
                class="ft-align-right font-medium-3"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>