import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { UntypedFormControl } from '@angular/forms';
import {  NavigationEnd, Router } from '@angular/router';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  ActiveClinic = localStorage.getItem('ActiveClinic');
  activeRoute: string;
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = 'assets/img/Logo-Svg.svg';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  control = new UntypedFormControl();

  public config: any = {};

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private configService: ConfigService, 
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private cdr: ChangeDetectorRef
  ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Get the current route URL
        const urlSegments = this.router.url.split('/');
        // Take the first segment of the route
        this.activeRoute = urlSegments[1];
        console.log('this.router.url',this.router.url);
        console.log('this.activeRoute',this.activeRoute);
      }
    });

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  // Date Range Picker
  
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }
  
  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }
  
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }
  
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  
  formatRange(): string {
    if (this.fromDate && this.toDate) {
      const fromDateStr = this.formatter.format(this.fromDate);
      const toDateStr = this.formatter.format(this.toDate);
      return `${fromDateStr} - ${toDateStr}`;
    }
    return '';
  }
  
  parseRange(input: string) {
    const parts = input.split(' - ');
    if (parts.length === 2) {
      const fromDate = this.formatter.parse(parts[0]);
      const toDate = this.formatter.parse(parts[1]);
      if (fromDate && toDate && this.calendar.isValid(NgbDate.from(fromDate)) && this.calendar.isValid(NgbDate.from(toDate))) {
        this.fromDate = NgbDate.from(fromDate);
        this.toDate = NgbDate.from(toDate);
        return;
      }
    }
    this.fromDate = null;
    this.toDate = null;
  }

  // End

  ngOnInit() {

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Light") {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }


  }


  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  setActiveClinic(e){
    // this.loadingService.setLoading(true);
    localStorage.setItem('ActiveClinic', e );
    
    let view;

    // if(this.userRole === 'Global Admin'){
    //   view = 'admin';
    // }else if(this.userRole === 'Clinic Admin'){
    //   view = 'clinic';
    // }else if(this.userRole === 'Clinician'){
    //   view = 'clinician';
    // }else{
    //   view = 'office';
    // }
    // this.router.navigate(['/dashboard/', view]);
  }



  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }
}
